var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"customizer unit-filter-report",class:{ open: _vm.isCustomizerOpen }},[_c('b-link',{staticClass:"customizer-toggle d-flex align-items-center justify-content-center",on:{"click":function($event){_vm.isCustomizerOpen = !_vm.isCustomizerOpen}}},[_c('slidersIcon')],1),_c('div',{staticClass:"customizer-section-report d-flex justify-content-between align-items-center top-control"},[_c('div',[_c('h4',{staticClass:"p-1 header-report-filter"},[_vm._v(" "+_vm._s(_vm.$t("ConsumerDashboard.UnitFilterAndGroupFilter"))+" ")])]),_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon","size":"18"},on:{"click":function($event){_vm.isCustomizerOpen = !_vm.isCustomizerOpen}}})],1),_c('div',{staticClass:"report-main-scroll"},[_c('treeselect',{ref:"treeselect",staticClass:"pl-1 pr-1",attrs:{"disabled":_vm.$route.name !== 'select-unit',"multiple":false,"options":_vm.reportCategories,"searchable":true,"disable-branch-nodes":true,"clear-on-select":true,"allowClearingDisabled":true,"clearable":false},on:{"select":function (e) {
          _vm.reportTypeChange(e);
        }},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
        var node = ref.node;
return _c('div',{staticClass:"tree-select-opction",attrs:{"title":node.label}},[_vm._v(" "+_vm._s(node.label)+" ")])}}]),model:{value:(_vm.report.report_type),callback:function ($$v) {_vm.$set(_vm.report, "report_type", $$v)},expression:"report.report_type"}}),_c('parameter-components',{attrs:{"parameters":_vm.report && _vm.report.parameters,"isEditable":!_vm.isEditable},model:{value:(_vm.report.parameters),callback:function ($$v) {_vm.$set(_vm.report, "parameters", $$v)},expression:"report.parameters"}}),(
        _vm.checkAbility({
          action: _vm.constants.PERMISSIONS_ACTION.SUB_ACCOUNT_REPORT_GENERATE,
          subject: _vm.constants.PERMISSIONS_MODEL.REPORTS
        })
      )?_c('div',{staticClass:"customizer-section-report auto-search-padding-0"},[_c('AccountSearch',{ref:"accountSearch",attrs:{"isViewableOnly":_vm.isEditable ? false : true,"perfectScrollbarSettings":"","placeholderName":_vm.$t('device.AllocatedAccount'),"accountsList":[],"isDisableConsumer":_vm.report.disable_unit},model:{value:(_vm.selectedAccount),callback:function ($$v) {_vm.selectedAccount=$$v},expression:"selectedAccount"}}),(_vm.report.disable_unit)?_c('div',{staticClass:"sim-checkbox"},[_c('b-form-checkbox',{staticClass:"mr-0",attrs:{"name":"is-menu-visible","inline":""},model:{value:(_vm.isAllSims),callback:function ($$v) {_vm.isAllSims=$$v},expression:"isAllSims"}},[_vm._v("is all sims")])],1):_vm._e(),(_vm.report.disable_unit)?_c('div',{staticClass:"sim-dropdown"},[_c('Dropdown',_vm._b({ref:"dropdownRef",model:{value:(_vm.sims),callback:function ($$v) {_vm.sims=$$v},expression:"sims"}},'Dropdown',Object.assign({}, _vm.simComponent,
            {isEditable: _vm.isAllSims,
            account_id: _vm.selectedAccount}),false))],1):_vm._e()],1):_vm._e(),(_vm.report.processor)?_c('div',{staticClass:"customizer-section-report unit-search-comp-ui"},[(_vm.reportData)?_c('NotificationUnitsSelection',{attrs:{"isEditable":_vm.isEditable,"selectedUnitsData":_vm.selectedUnitsData,"reportType":_vm.report.processor,"sensors":_vm.reportData && _vm.reportData.sensors && _vm.reportData.sensors.length
            ? _vm.reportData.sensors
            : [],"isViewableOnly":_vm.isEditable && !_vm.isAllSims ? false : true,"selectedUnitByUser":_vm.selectedUnitByUser,"disableUnit":_vm.report.disable_unit,"isClear":_vm.isClear,"alertId":_vm.alertId,"notification":_vm.notification,"componentKey":'report',"unitFun":_vm.unitFun,"getUnitLookupCount":_vm.getUnitLookupCount,"totalLookUpCount":_vm.totalLookUpCount,"removedSelectedItem":_vm.removedSelectedItem,"accountId":_vm.report.account_id,"isAllSelectedUnit":_vm.isAllSelectedUnit,"isAllUnitEnabled":_vm.isAllUnitEnabled}}):_vm._e()],1):_vm._e()],1),_c('div',{staticClass:"report-generate"},[_c('b-button',{staticClass:"btn-tour-next",attrs:{"disabled":(!_vm.report.disable_unit && _vm.isValid()) ||
        !_vm.isEditable ||
        (!_vm.isSimValid && _vm.report.disable_unit),"variant":"primary"},on:{"click":_vm.apply}},[_vm._v(" "+_vm._s(_vm.$route.name === "edit-report" || _vm.$route.name === "view-report" ? _vm.$t("ReportsData.ReGenerateReport") : _vm.$t("ReportsData.GenerateReport"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }