<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <app-breadcrumb class="account-name" />
    <!-- <b style="padding-right: 15px" class="account-name">{{ accountName }}</b> -->
    <!-- <span id="button-1">
      <feather-icon
        size="16"
        icon="RefreshCcwIcon"
        class="mr-50 cursor-pointer"
        v-if="accounts && accounts.length > 1"
        @click="getUserAccounts(1)"
      />
    </span>
    <b-tooltip
      target="button-1"
      :title="$t('Switch Account')"
      variant="primary"
    ></b-tooltip> -->
    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    ></div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none dark-toggle d-lg-block" />
      <locale />

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user access-on-all-pages"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ accountName }}
            </p>
            <span class="user-status"
              >{{ user.first_name }} {{ user.last_name }}
              {{ roleName ? `(${roleName})` : "" }}</span
            >
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="user.profile_pic_url"
            :text="
              user.first_name &&
              user.first_name.substring(0, 1) +
                (user.last_name && user.last_name
                  ? user.last_name.substring(0, 1)
                  : '')
            "
            class="badge-minimal user-profile"
            badge-variant="success"
          />
        </template>
        <div class="display-accout-name text-primary">
          {{ accountName }}
        </div>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'profile' }"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>{{ $t("navbar.Profile") }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'user-invitations' }"
        >
          <feather-icon size="16" icon="MailIcon" class="mr-50" />
          <span>{{ $t("navbar.Invitations") }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          v-if="accounts && accounts.length > 1"
          @click="getUserAccounts(1, 1)"
        >
          <feather-icon size="16" icon="RefreshCcwIcon" class="mr-50" />
          <span>{{ $t("navbar.SwitchAccount") }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'settings' }"
        >
          <feather-icon size="16" icon="LockIcon" class="mr-50" />
          <span>{{ $t("navbar.ChangePassword") }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="
            getCurrentAccount() === 'DEALER'
              ? checkAbility({
                  action: constants.PERMISSIONS_ACTION.UPDATE,
                  subject: constants.PERMISSIONS_MODEL.ACCOUNT
                })
              : getCurrentAccount() === 'CONSUMER'
              ? checkAbility({
                  action: constants.PERMISSIONS_ACTION.UPDATE_CONSUMER,
                  subject: constants.PERMISSIONS_MODEL.CONSUMER_ACCOUNT
                })
              : false
          "
          link-class="d-flex align-items-center account-setting"
          :to="{
            name:
              getCurrentAccount() === 'DEALER' &&
              checkAbility({
                action: constants.PERMISSIONS_ACTION.UPDATE,
                subject: constants.PERMISSIONS_MODEL.ACCOUNT
              })
                ? 'account-update'
                : getCurrentAccount() === 'CONSUMER' &&
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.UPDATE_CONSUMER,
                    subject: constants.PERMISSIONS_MODEL.CONSUMER_ACCOUNT
                  })
                ? 'consumer-account-update'
                : ''
          }"
        >
          <AccountSetting class="acc-icon" />
          <span>{{ $t("navbar.AccountSettings") }}</span>
        </b-dropdown-item>
        <!-- <b-dropdown-item
          link-class="d-flex align-items-center custom-drop mobile-hidden"
        >
          <feather-icon size="16" icon="ChevronLeftIcon" class="mr-50" />
          <span>{{ $t("navbar.Theme") }}</span>
          <div class="sub-menu">
            <div
              v-for="theme in constants.THEMES"
              :key="theme.value"
              :class="'theme-doth ' + theme.value"
              @click="toggleBodyClass('addClass', theme.value)"
            ></div>
          </div>
        </b-dropdown-item> -->
        <b-dropdown-item
          link-class="d-flex align-items-center"
          v-b-modal.modal-active-sessions
          @click="activeSessions"
        >
          <ActiveSessionsIcon class="acc-icon" />
          <span>{{ $t("navbar.ActiveSessions") }}</span>
        </b-dropdown-item>
        <ActiveSessions
          :activeSessionsOpen="activeSessionsOpen"
          @activeSessions="activeSessions"
        />
        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $t("navbar.Logout") }}</span>
        </b-dropdown-item>
        <!-- <div class="d-flex align-items-center custom-drop mobile-show">
          <div class="sub-menu">
            <div
              v-for="theme in constants.THEMES"
              :key="theme.value"
              :class="'theme-doth ' + theme.value"
              @click="toggleBodyClass('addClass', theme.value)"
            ></div>
          </div>
        </div> -->
      </b-nav-item-dropdown>
      <!-- <b-overlay :show="show" no-wrap> </b-overlay> -->
    </b-navbar-nav>
    <UserOnboarding
      :inviteData="inviteData"
      :joinWithUserInvite="joinWithUserInvite"
    />
    <SwitchAccount
      :accounts="accounts"
      :isSelectedId="isSelectedId"
      :mouseout="mouseout"
      :mouseover="mouseover"
      :selectedUserAccount="selectedUserAccount"
    />
    <Loader :show="show" />
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  OverlayPlugin,
  BOverlay,
  BTooltip,
  VBTooltip
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import Locale from "./Locale.vue";
import { v4 as uuidv4 } from "uuid";
import AccountService from "@/libs/api/account-service";
import SwitchAccount from "@/layouts/components/SwitchAccount.vue";
import UserOnboarding from "@/layouts/components/UserOnboarding.vue";
import AuthService from "@/libs/api/auth-service";
import Loader from "@/layouts/components/Loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import acl from "@/utils/acl";
import EventBus from "./../../EventBus.js";
import constants from "@/utils/constants";
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";
import AccountSetting from "@/assets/images/new-icon/account-setting.svg";
import ActiveSessionsIcon from "@/assets/images/new-icon/active-sessions.svg";
import Menus from "@/navigation/vertical/index";
import { localeChanged } from "vee-validate";
import store from "@/store";
import ActiveSessions from "@/layouts/components/ActiveSessions/ActiveSessions.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    SwitchAccount,
    UserOnboarding,
    // Navbar Components
    Loader,
    DarkToggler,
    Locale,
    OverlayPlugin,
    BOverlay,
    BTooltip,
    AppBreadcrumb,
    AccountSetting,
    ActiveSessions,
    ActiveSessionsIcon
  },
  data() {
    return {
      show: false,
      activeUser: "select-account-row active-primary",
      accounts: [],
      isSelectedId: "",
      isOldSelectedId: "",
      modalShow: "modal-sm-switch-account",
      downloadUrl: process.env.VUE_APP_FILE_URL,
      inviteData: {},
      isInvitation: false,
      loggedUserId: localStorage.getItem("USER_LOGIN_ID"),
      accountName: localStorage.getItem("USER_ACCOUNT_NAME"),
      roleName: localStorage.getItem("USER_ACCOUNT_ROLE_NAME"),
      activeSessionsOpen: false
    };
  },
  created() {
    EventBus.$on("data-updated", this.handleDataUpdate);
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapState("user", ["user"])
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  methods: {
    ...mapMutations("user", ["setUserAccount"]),
    handleDataUpdate(data) {
      this.getProfileDetails();
    },
    activeSessions() {
      this.activeSessionsOpen = !this.activeSessionsOpen;
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      document.body.classList.remove(
        "blue-layout",
        "pink-layout",
        "red-layout",
        "orange-layout",
        "yellow-layout",
        "green-layout",
        "teal-layout",
        "cyan-layout"
      );
      document.body.classList.add(className);
      localStorage.setItem("savedClass", className);
    },

    async getUserAccounts(isReload, isSwitch) {
      try {
        if (this.$router.name === "forgot-password-II") {
          return false;
        }
        this.show = true;
        const el = document.body;
        el.className = localStorage.getItem("savedClass");
        el.classNameSkin = localStorage.getItem("vuexy-skin");
        if (el.classNameSkin == "dark") {
          document.body.classList.add("dark-layout");
        }

        let response = await new AccountService().getUserAccounts({});
        if (localStorage.getItem("ACCOUNT_ID")) {
          localStorage.setItem(
            "OLD_USER_ACCOUNT",
            localStorage.getItem("ACCOUNT_ID")
          );
        }

        if (
          response &&
          response.data &&
          response.data &&
          response.data.length
        ) {
          this.show = false;
          this.accounts = response.data;
          this.accounts = this.accounts.map((l) => {
            l.variant = this.getRandomBgColor();
            l.avtar_txt = this.userAvatarTxt(l.account_name);

            return l;
          });
          const accountIds = this.accounts.map((e) => e.account_id);
          if (
            localStorage.getItem("USER_LOGIN_ID") === this.loggedUserId &&
            localStorage.getItem("ACCOUNT_ID") &&
            accountIds.includes(localStorage.getItem("ACCOUNT_ID")) &&
            !isSwitch
          ) {
            const lastSelectedAccount = this.accounts.find(
              (e) => e.account_id === localStorage.getItem("ACCOUNT_ID")
            );
            if (lastSelectedAccount && lastSelectedAccount.account_id) {
              localStorage.setItem(
                "USER_ACCOUNT_ID",
                lastSelectedAccount.account_id
              );

              localStorage.setItem(
                "ACCOUNT_ID",
                lastSelectedAccount.account_id
              );
              localStorage.setItem(
                "USER_ACCOUNT_NAME",
                lastSelectedAccount.account_name
              );
              this.accountName = lastSelectedAccount.account_name;
              if (this.loggedUserId) {
                localStorage.setItem("USER_LOGIN_ID", this.loggedUserId);
              }
              localStorage.setItem(
                "USER_ACCOUNT_TYPE",
                lastSelectedAccount.type
              );
              store.commit("account/setAccountType", lastSelectedAccount.type);

              this.whiteLabeling(lastSelectedAccount);
              this.isOldSelectedId = lastSelectedAccount.account_id;

              this.selectedUserAccount(lastSelectedAccount);
            }
          } else if (this.accounts && this.accounts.length === 0) {
            if (localStorage.getItem("USER_PERMISSIONS")) {
              localStorage.removeItem("USER_PERMISSIONS");
              this.$router.go();
            }
            store.commit("authorized/SET_IS_LOGIN", true);
            // this.$router.push({ name: "home" }).catch((e) => {});
            //this.$router.go();
          } else if (this.accounts && this.accounts.length <= 1) {
            if (this.accounts && this.accounts.length === 1) {
              localStorage.setItem(
                "USER_ACCOUNT_ID",
                this.accounts[0].account_id
              );
              localStorage.setItem("ACCOUNT_ID", this.accounts[0].account_id);
              localStorage.setItem(
                "USER_ACCOUNT_NAME",
                this.accounts[0].account_name
              );
              if (this.loggedUserId) {
                localStorage.setItem("USER_LOGIN_ID", this.loggedUserId);
              }

              localStorage.setItem("USER_ACCOUNT_TYPE", this.accounts[0].type);

              store.commit("account/setAccountType", this.accounts[0].type);
              this.whiteLabeling(this.accounts[0]);
              this.isOldSelectedId = this.accounts[0].account_id;
              this.accountName = this.accounts[0].account_name;
            }
            this.$bvModal.hide("modal-sm-switch-account");
            this.selectedUserAccount(this.accounts[0]);
            this.showAcceptInvitationScreen();
          } else {
            if (isReload) {
              this.$bvModal.show("modal-sm-switch-account");
            }
          }
          this.accounts.forEach((a) => {
            let accountId;
            if (localStorage.getItem("USER_ACCOUNT_ID")) {
              accountId = localStorage.getItem("USER_ACCOUNT_ID");
            }

            if (a.account_id === accountId) {
              this.isSelectedId = accountId;
              this.isOldSelectedId = accountId;
              this.whiteLabeling(a);
              localStorage.setItem(
                "USER_SUB_ACCOUNTS_RESTRICTED",
                a.restrict_sub_accounts
              );
            }
          });
          // this.$router.push({ name: "home" }).catch((e) => {});
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        } else {
          this.show = false;
          if (localStorage.getItem("USER_PERMISSIONS")) {
            localStorage.removeItem("USER_PERMISSIONS");
            this.$router.go();
          }
          //   this.$router.go();
          this.$router.push({ name: "user-invitations" }).catch((e) => {});

          //  this.showAcceptInvitationScreen();
        }
      } catch (err) {
        this.show = false;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    mouseover(id) {
      this.isSelectedId = id;
    },
    mouseout(id) {
      this.isSelectedId = this.isOldSelectedId;
    },
    getCurrentAccount() {
      return localStorage.getItem("USER_ACCOUNT_TYPE") || null;
    },
    getFilteredLists(filter, response) {
      response.data = Object.keys(response.data)
        .filter((key) => !filter.includes(key))
        .reduce((obj, key) => {
          obj[key] = response.data[key];
          return obj;
        }, {});
    },
    async selectedUserAccount(account, isRedirect) {
      const accountId = localStorage.getItem("OLD_USER_ACCOUNT");
      const requestData = {};
      if (
        !accountId ||
        accountId != account.account_id ||
        !localStorage.getItem("SESSION_LOGIN")
      ) {
        const newSession = uuidv4();
        const oldSession = localStorage.getItem("SESSION_LOGIN");
        localStorage.setItem("SESSION_LOGIN", newSession);
        if (oldSession) {
          requestData.old_session = oldSession;
        }

        requestData.new_session = newSession;
        requestData.account_id = account.account_id;
        if (accountId) {
          requestData.old_account_id = accountId;
        }
      }
      localStorage.setItem("USER_ACCOUNT_ID", account.account_id);
      localStorage.setItem(
        "USER_SUB_ACCOUNTS_RESTRICTED",
        account.restrict_sub_accounts
      );
      localStorage.setItem("ACCOUNT_ID", account.account_id);
      localStorage.setItem("USER_ACCOUNT_TYPE", account.type);

      store.commit("account/setAccountType", account.type);
      localStorage.setItem("USER_ACCOUNT_ROLE_NAME", account.role);
      localStorage.setItem("USER_ACCOUNT_NAME", account.account_name);
      localStorage.setItem("USER_ACCOUNT_ROLE_ID", account.main_role);
      if (this.loggedUserId) {
        localStorage.setItem("USER_LOGIN_ID", this.loggedUserId);
      }

      this.whiteLabeling(account);
      this.isOldSelectedId = account.account_id;
      this.accountName = account.account_name;
      // call select account api.
      let response = await new AccountService().selectAccount({
        account_id: account.account_id,
        ...requestData
      });
      store.commit("authorized/SET_IS_LOGIN", true);
      if (response && response.data) {
        if (localStorage.getItem("USER_SUB_ACCOUNTS_RESTRICTED") == "true") {
          if (
            ["DEALER", "CONSUMER"].includes(
              localStorage.getItem("USER_ACCOUNT_TYPE")
            )
          ) {
            this.getFilteredLists(["account", "account_devices"], response); //pass in array which we want to exclude
          }
        } else if (localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER") {
          //  this.getFilteredLists(["devices"], response);
        }

        acl.parseAndStoreAbilities(response.data, false, true);
        this.$bvModal.hide("modal-sm-switch-account");
        if (isRedirect) {
          if (
            this.checkAbility({
              action: constants.LABELS.MENU,
              subject: "units"
            }) &&
            localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER"
          ) {
            const query = localStorage.getItem("USER_ACCOUNT_ID")
              ? { account_id: localStorage.getItem("USER_ACCOUNT_ID") }
              : null;
            this.$router
              .push({
                name: "unit-map",
                query
              })
              .catch((err) => {});
            setTimeout(() => {
              this.$router.go();
            }, 200);
          } else if (
            this.checkAbility({
              action: constants.LABELS.DASHBOARD,
              subject: "account"
            }) &&
            localStorage.getItem("USER_ACCOUNT_TYPE") === "DEALER"
          ) {
            this.$router.push({
              name: "home"
            });
            setTimeout(() => {
              this.$router.go();
            }, 100);
          } else if (
            this.checkAbility({
              action: constants.LABELS.DASH,
              subject: "widgets"
            }) &&
            localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER"
          ) {
            this.$router.push({
              name: "dashboard"
            });
            setTimeout(() => {
              this.$router.go();
            }, 100);
          } else {
            try {
              Menus.forEach((element) => {
                if (
                  this.checkAbility({
                    action: element.action,
                    subject: element.resource
                  }) &&
                  element.route
                ) {
                  throw element;
                } else {
                  if (element && element.children && element.children.length) {
                    element.children.forEach((child) => {
                      if (
                        this.checkAbility({
                          action: child.action,
                          subject: child.resource
                        })
                      ) {
                        throw child;
                      }
                    });
                  }
                }
              });
            } catch (e) {
              if (e.route) {
                this.$router.push({ name: e.route });
                setTimeout(() => {
                  this.$router.go();
                }, 100);
              } else {
                // localStorage.setItem("TEMP_URL", "user-invitations");
                this.$router.push({ name: "user-invitations" });
                setTimeout(() => {
                  this.$router.go();
                }, 100);
                // if (oldPermission && oldPermission !== newPermission) {
                //   this.$router.go();
                // } else {
                //   this.$router.push({ name: "user-invitations" });
                // }
              }
            }
          }
        }

        // this.showAcceptInvitationScreen();
      } else if (response && response.error && response.error.message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error.title,
            text: response.error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    async getProfileDetails() {
      try {
        if (!localStorage.getItem("accessToken")) {
          return false;
        }

        this.show = true;
        let response = await new AuthService().getUserProfile({});

        if (response && response.data) {
          this.show = false;
          this.setUserAccount(response.data);
          localStorage.setItem(
            "USER_PROFILE_DETAILS",
            JSON.stringify(response.data)
          );
          this.loggedUserId = response.data.id;
          this.showAcceptInvitationScreen();
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    whiteLabeling(account) {
      localStorage.setItem(
        "USER_ACCOUNT_DETAILS",
        JSON.stringify(Object.assign({}, account))
      );
      if (account && account.white_labeling) {
        if (account.white_labeling.theme) {
          this.toggleBodyClass(null, account.white_labeling.theme);
        }
        if (account.white_labeling.lang && !localStorage.getItem("lang")) {
          this.$i18n.locale = account.white_labeling.lang;
          localStorage.setItem("lang", this.$i18n.locale);
          localeChanged();
        }
        store.commit("device/setIcons", []);
      }
    },
    async logout() {
      await new AuthService().logout({
        session_id: localStorage.getItem("sessionId"),
        token: localStorage.getItem("accessToken")
      });
      this.setUserAccount({});
      // Redirect to login page
      this.$router.push({ name: "login" });
    },
    async joinWithUserInvite(action) {
      try {
        this.show = true;
        const body = {
          token: this.inviteData.token,
          action: action
        };
        let response = await new AccountService().joinUserWithInvite(body);

        if (response && response.data) {
          this.show = false;
          const inviteMessage =
            action === "ACCEPT"
              ? this.$t("user.InvitationAccept")
              : this.$t("user.InvitationDecline");

          this.$toast({
            component: ToastificationContent,
            props: {
              title: inviteMessage,
              icon: "InfoIcon",
              variant: "success"
            }
          });
          this.$bvModal.hide("modal-accept-invite");
          localStorage.removeItem("inviteData");
          this.isInvitation = false;
          this.getUserAccounts(1, 0);
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          this.$bvModal.hide("modal-accept-invite");
          this.isInvitation = false;
          localStorage.removeItem("inviteData");
        }
      } catch (err) {
        this.show = false;

        this.$toast({
          component: ToastificationContent,
          props: {
            text: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    showAcceptInvitationScreen() {
      const inviteData =
        localStorage.getItem("inviteData") &&
        JSON.parse(localStorage.getItem("inviteData"));
      const user =
        localStorage.getItem("USER_PROFILE_DETAILS") &&
        JSON.parse(localStorage.getItem("USER_PROFILE_DETAILS"));
      if (
        user &&
        inviteData &&
        user.email.toLowerCase() === inviteData.email.toLowerCase()
      ) {
        this.isInvitation = true;
        this.$bvModal.show("modal-accept-invite");
      }
    }
  },

  mounted() {
    if (this.$router.name !== "forgot-password-II") {
      if (localStorage.getItem("TEMP_URL")) {
        const routerName = localStorage.getItem("TEMP_URL");
        localStorage.removeItem("TEMP_URL");
        this.$router.push({ name: routerName });
      }
      if (!localStorage.getItem("USER_PROFILE_DETAILS")) {
        this.getProfileDetails();
      } else {
        this.setUserAccount(
          JSON.parse(localStorage.getItem("USER_PROFILE_DETAILS"))
        );
      }
      if (localStorage.getItem("USER_ACCOUNT_ID")) {
        this.getUserAccounts(0, 0);
      } else {
        this.getUserAccounts(1, 0);
      }
      this.inviteData =
        localStorage.getItem("inviteData") &&
        JSON.parse(localStorage.getItem("inviteData"));
      this.showAcceptInvitationScreen();
    }
  },
  beforeDestroy() {
    EventBus.$off("check-is-have-unit-or-group", this.handleDataUpdate);
  }
};
</script>
<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.custom-drop {
  position: relative;
  .sub-menu {
    display: none;
  }
  &:hover .sub-menu {
    display: block;
    position: absolute;
    left: -150px;
    background: #fff;
    width: 150px;
    padding: 15px;
    box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
    border-radius: 6px;
  }
  .theme-doth {
    height: 30px;
    width: 30px;
    border-radius: 30px;
    float: left;
    margin: 3px 4px;
  }
}
.acc-icon {
  margin-right: 7px;
  path {
    fill: var(--body-color);
  }
}
.account-setting:hover {
  .acc-icon {
    path {
      fill: var(--primary);
    }
  }
}
.display-accout-name {
  display: none;
}
@media only screen and (max-width: 991px) {
  .display-accout-name {
    display: block;
    padding: 0.65rem 1.28rem;
    font-weight: 500;
  }
}
.mr-custom-1 {
  margin-right: 8px;
  svg {
    color: var(--body-color);
  }
}
.dark-layout {
  .mr-custom-1 {
    svg {
      color: #d0d2d6;
    }
  }
}
</style>
