<template>
  <div
    class="customizer unit-filter-report"
    :class="{ open: isCustomizerOpen }"
  >
    <!-- Toggler -->
    <b-link
      class="customizer-toggle d-flex align-items-center justify-content-center"
      @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <slidersIcon />

      <!-- <feather-icon icon="FilterIcon" size="15" /> -->
    </b-link>
    <div
      class="customizer-section-report d-flex justify-content-between align-items-center top-control"
    >
      <div>
        <h4 class="p-1 header-report-filter">
          {{ $t("ConsumerDashboard.UnitFilterAndGroupFilter") }}
        </h4>
        <!-- <small>Customize &amp; Preview in Real Time</small> -->
      </div>
      <feather-icon
        icon="XIcon"
        size="18"
        class="cursor-pointer"
        @click="isCustomizerOpen = !isCustomizerOpen"
      />
    </div>
    <div class="report-main-scroll">
      <treeselect
        :disabled="$route.name !== 'select-unit'"
        v-model="report.report_type"
        class="pl-1 pr-1"
        :multiple="false"
        :options="reportCategories"
        :searchable="true"
        :disable-branch-nodes="true"
        ref="treeselect"
        :clear-on-select="true"
        :allowClearingDisabled="true"
        :clearable="false"
        @select="
          (e) => {
            reportTypeChange(e);
          }
        "
      >
        <div
          slot="option-label"
          class="tree-select-opction"
          slot-scope="{ node }"
          :title="node.label"
        >
          {{ node.label }}
        </div>
      </treeselect>
      <!-- Header -->

      <!-- <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="ps-customizer-area scroll-area"
      > -->
      <parameter-components
        :parameters="report && report.parameters"
        v-model="report.parameters"
        :isEditable="!isEditable"
      />
      <div
        class="customizer-section-report auto-search-padding-0"
        v-if="
          checkAbility({
            action: constants.PERMISSIONS_ACTION.SUB_ACCOUNT_REPORT_GENERATE,
            subject: constants.PERMISSIONS_MODEL.REPORTS
          })
        "
      >
        <AccountSearch
          ref="accountSearch"
          :isViewableOnly="isEditable ? false : true"
          v-model="selectedAccount"
          perfectScrollbarSettings
          :placeholderName="$t('device.AllocatedAccount')"
          :accountsList="[]"
          :isDisableConsumer="report.disable_unit"
        />
        <div class="sim-checkbox" v-if="report.disable_unit">
          <b-form-checkbox
            v-model="isAllSims"
            name="is-menu-visible"
            class="mr-0"
            inline
            >is all sims</b-form-checkbox
          >
        </div>
        <div class="sim-dropdown" v-if="report.disable_unit">
          <Dropdown
            ref="dropdownRef"
            v-model="sims"
            v-bind="{
              ...simComponent,
              isEditable: isAllSims,
              account_id: selectedAccount
            }"
          />
        </div>
      </div>
      <div
        class="customizer-section-report unit-search-comp-ui"
        v-if="report.processor"
      >
        <NotificationUnitsSelection
          v-if="reportData"
          :isEditable="isEditable"
          :selectedUnitsData="selectedUnitsData"
          :reportType="report.processor"
          :sensors="
            reportData && reportData.sensors && reportData.sensors.length
              ? reportData.sensors
              : []
          "
          :isViewableOnly="isEditable && !isAllSims ? false : true"
          :selectedUnitByUser="selectedUnitByUser"
          :disableUnit="report.disable_unit"
          :isClear="isClear"
          :alertId="alertId"
          :notification="notification"
          :componentKey="'report'"
          :unitFun="unitFun"
          :getUnitLookupCount="getUnitLookupCount"
          :totalLookUpCount="totalLookUpCount"
          :removedSelectedItem="removedSelectedItem"
          :accountId="report.account_id"
          :isAllSelectedUnit="isAllSelectedUnit"
          :isAllUnitEnabled="isAllUnitEnabled"
        ></NotificationUnitsSelection>
      </div>
    </div>
    <!-- </vue-perfect-scrollbar> -->
    <div class="report-generate">
      <!-- <b-img :src="slidersIcon" alt="Sliders Icon" /> -->
      <b-button
        @click="apply"
        :disabled="
          (!report.disable_unit && isValid()) ||
          !isEditable ||
          (!isSimValid && report.disable_unit)
        "
        variant="primary"
        class="btn-tour-next"
      >
        <!-- {{ $t("Report.Generate") }}   {{ $t("Report.Regenerate") }} -->
        <!-- {{ $t("Apply") }} -->
        {{
          $route.name === "edit-report" || $route.name === "view-report"
            ? $t("ReportsData.ReGenerateReport")
            : $t("ReportsData.GenerateReport")
        }}
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BFormRadioGroup,
  BFormGroup,
  BLink,
  BButton,
  BImg,
  BFormCheckbox
} from "bootstrap-vue";
import AccountSearch from "@/views/Unit/Unit-V2/AccountSearch.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import useAppCustomizer from "@/@core/layouts/components/app-customizer/useAppCustomizer";
// import DashboardUnitFilter from "@/views/Dashboard/Consumer/DashboardUnitFilter.vue";
import EventBus from "@/EventBus";
import NotificationUnitsSelection from "@/views/Notification/NotificationUnitsSelection.vue";
import slidersIcon from "@/assets/images/icons/slidersIcon.svg";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ReportService from "@/libs/api/report-service";
import constants from "@/utils/constants";
import ParameterComponents from "./parameterComponent/ParameterComponents.vue";
import Treeselect from "@riophae/vue-treeselect";
import store from "@/store";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Dropdown from "./parameterComponent/Dropdown.vue";
export default {
  components: {
    // BSV
    AccountSearch,
    BFormRadioGroup,
    useAppCustomizer,
    BFormGroup,
    VuePerfectScrollbar,
    BLink,
    // DashboardUnitFilter,
    BButton,
    NotificationUnitsSelection,
    BImg,
    slidersIcon,
    ParameterComponents,
    Treeselect,
    Dropdown,
    BFormCheckbox
  },

  setup() {
    const {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen

      // Skin
    } = useAppCustomizer();

    // Perfect Scrollbar
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false
    };

    return {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer

      perfectScrollbarSettings
    };
  },
  data() {
    return {
      isAllSims: false,
      sims: [],
      simComponent: {
        desc: "SIM ",
        name: "simId",
        label: "SIM's",
        type: "array",
        value: "",
        component: "dropdown",
        lookup: {
          enable: true,
          key: "name",
          value: "value",
          method: "getSimsNameList",
          service: "device-service",
          filters: [{ field: "sim_number", operator: "ilike", value: "" }],
          multiple: true
        },
        required: true,
        is_multiple: true,
        options: [],
        component_value: []
      },
      alertId: "",
      isCustomizerOpen: true,
      report_img_url: require("@/assets/images/pages/report-bg.jpg"),
      selectedUnitData: [],
      isAllSelectedUnit: false,
      reportData: null,

      isClear: false,
      selectedUnitsData: [],
      removedSelectedItem: {},

      isEditable: true,
      selectedUnitsData: [],
      reportCategories: [],
      totalLookUpCount: 0,
      removedSelectedItem: {},
      isHideLogScreen: false,
      getSelectedUnit: {
        selected: [],
        unselected: []
      },
      selectedUnselectedFinalItems: {
        unit: { selected: [], unselected: [] },
        group: { selected: [], unselected: [] }
      },
      selectedUnselectedItems: {
        unit: { selected: [], unselected: [] },
        group: { selected: [], unselected: [] },
        isAllUnit: true
      },
      selectedUnitByUser: [],
      report: {
        report_name: "",
        processor: null,
        report_type: null,
        labels: [],
        entities: [],
        sensors: [],
        disable_unit: false,
        parameters: [],
        account_id: localStorage.getItem("USER_ACCOUNT_ID") || "",
        id: null
      },
      selectedAccount: localStorage.getItem("USER_ACCOUNT_ID") || "",
      sliderConfiguration: {},
      dateRange: null,
      notification: {
        name: "",
        status: true,
        alert_id: "",
        preferences: [],
        units: {},
        alert_name: ""
      },
      isApplyDisabledFlag: false,
      isSimValid: false
    };
  },
  created() {
    EventBus.$on("check-is-have-unit-or-group", this.isApplyDisabled);
  },
  watch: {
    $route(to, from) {
      if (to.name === "select-unit" && to.query.report_type) {
        this.report.processor = this.$route.query.report_type;
        this.report.report_type = this.$route.query.report_type;
        if (
          this.$route.name === "select-unit" &&
          this.checkAbility({
            action: constants.PERMISSIONS_ACTION.ADD,
            subject: constants.PERMISSIONS_MODEL.REPORTS
          }) &&
          this.$route.query.report_type
        ) {
          this.getAllReportCategories();
          this.getReportTypeDetails();
        }
      } else if (to.name === "select-unit" && to.query.report_type) {
        this.report.processor = this.$route.query.report_type;
        this.report.report_type = this.$route.query.report_type;
      } else if (
        this.$route.name === "edit-report" &&
        this.checkAbility({
          action: constants.PERMISSIONS_ACTION.UPDATE,
          subject: constants.PERMISSIONS_MODEL.REPORTS
        }) &&
        this.$route.params.id
      ) {
        this.getAllReportCategories();
        this.getCreatedReportAPI();
      } else if (
        this.$route.name === "view-report" &&
        this.checkAbility({
          action: constants.PERMISSIONS_ACTION.UPDATE,
          subject: constants.PERMISSIONS_MODEL.REPORTS
        }) &&
        this.$route.params.id
      ) {
        this.getAllReportCategories();
        this.getCreatedReportAPI();
      }
    },
    report: {
      deep: true,
      handler(val) {
        // console.log(val);
      }
    },
    isCustomizerOpen() {
      EventBus.$emit("is-customizer-open", this.isCustomizerOpen);
    },
    selectedAccount(val) {
      this.report.account_id = val;
      if (this.report && this.report.disable_unit) {
        if (this.$refs.dropdownRef) {
          this.$refs.dropdownRef.getData();
        }
      }
    },
    "$store.state.report.enable_generate"(val) {
      if (!val) {
        this.isApplyDisabledFlag = false;
      } else {
        this.isApplyDisabledFlag = true;
      }
    },
    isAllSims(val) {
      if (val) {
        this.isSimValid = true;
      } else {
        if (
          !this.sims.length &&
          this.selectedUnselectedItems &&
          !this.selectedUnselectedItems.group.selected.length
        ) {
          this.isSimValid = false;
        }
      }
    },
    sims() {
      if (
        this.sims.length ||
        this.isAllSims ||
        (this.selectedUnselectedItems &&
          this.selectedUnselectedItems.group.selected.length)
      ) {
        this.isSimValid = true;
      } else {
        this.isSimValid = false;
      }
    }
  },
  mounted() {
    this.report.processor = this.$route.query.report_type;
    this.report.report_type = this.$route.query.report_type;
    if (
      this.$route.name === "select-unit" &&
      this.checkAbility({
        action: constants.PERMISSIONS_ACTION.ADD,
        subject: constants.PERMISSIONS_MODEL.REPORTS
      }) &&
      this.$route.query.report_type
    ) {
      this.getAllReportCategories();
      this.getReportTypeDetails();
    } else if (
      this.$route.name === "edit-report" &&
      this.checkAbility({
        action: constants.PERMISSIONS_ACTION.ADD,
        subject: constants.PERMISSIONS_MODEL.REPORTS
      }) &&
      this.$route.params.id
    ) {
      this.getAllReportCategories();
      this.getCreatedReportAPI();
    } else if (
      this.$route.name === "view-report" &&
      this.checkAbility({
        action: constants.PERMISSIONS_ACTION.READ,
        subject: constants.PERMISSIONS_MODEL.REPORTS
      }) &&
      this.$route.params.id
    ) {
      this.getAllReportCategories();
      this.getCreatedReportAPI();
    }
    this.isEditable = this.$route.name === "view-report" ? false : true;
    // if (
    //   (this.$route.name === "edit-report" &&
    //     this.checkAbility({
    //       action: constants.PERMISSIONS_ACTION.UPDATE,
    //       subject: constants.PERMISSIONS_MODEL.REPORTS
    //     })) ||
    //   (this.$route.name === "view-report" &&
    //     this.checkAbility({
    //       action: constants.PERMISSIONS_ACTION.READ,
    //       subject: constants.PERMISSIONS_MODEL.REPORTS
    //     }))
    // ) {
    //   this.isEditable = this.$route.name === "edit-report" ? true : false;
    //   this.getCreatedReportAPI();
    // }
  },
  methods: {
    reportTypeChange(reportCategories) {
      this.getReportTypeDetails(reportCategories.id);
      let query = this.$route.query;
      this.$router
        .replace({
          query: { ...query, report_type: reportCategories.report_type }
        })
        .catch((e) => {});
      this.$router.replace();
    },
    async getReportTypeDetails(report_type) {
      try {
        this.show = true;
        const me = this;

        let response = await new ReportService().getReportTypeDetails({
          report_type: report_type ? report_type : this.report.processor
        });
        this.show = false;
        if (response && response.data) {
          // this.report.parameters = [];
          this.reportData = response.data;
          this.report.sensors = this.reportData.sensors;

          this.report.disable_unit = this.reportData.disable_unit;
          if (this.reportData.disable_unit && this.$refs.accountSearch) {
            setTimeout(() => {
              this.$refs.accountSearch.getChildAccounts();
            }, 1000);
          }
          this.report.parameters =
            this.$route.name === "edit-report"
              ? this.report.parameters
              : this.reportData.parameters;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    isAllUnitEnabled(val) {
      if (val) {
        this.isAllUnit = true;
        this.isAllSelectedUnit = true;
        this.report.is_all_units = true;
        this.report.entities = [];
      } else {
        this.isAllUnit = false;
        this.isAllSelectedUnit = false;
        this.report.is_all_units = false;
        this.report.entities = this.report.entities;
      }
    },
    isApplyDisabled(flag) {
      this.isApplyDisabledFlag = flag;
      if (
        this.report.status == "PROCESSING" ||
        this.report.status == "NOT_STARTED"
      ) {
        store.commit("report/SET_ENABLE_GENERATE", true);
        this.isApplyDisabledFlag = true;
      }
    },
    checkValidation(report) {
      for (let i = 0; i < report.parameters.length; i++) {
        if (report.parameters[i].name == "date_range") {
          if (!report.parameters[i].value) {
            return {
              success: true,
              field: this.$t("ReportsData.DateRange"),
              message: this.$t("ReportsData.DateRequired")
            };
          }
        }
      }
      return {
        success: false
      };
    },
    apply() {
      if (this.report.disable_unit) {
        if (this.isAllSims) {
          this.report.is_all_sims = this.isAllSims;
          this.report.entities = [];
        } else if (this.sims && this.sims.length) {
          const simEntities = this.sims.map((e) => {
            const a = {
              entity_id: e,
              entity_type: 2,
              source: "SIM"
            };
            return a;
          });
          const mergedMap = new Map();
          const groupsArray = this.report.entities.filter((e) => {
            if (e.id && e.source === "GROUP") {
              const a = {
                entity_id: e.id,
                entity_type: 1,
                source: "GROUP"
              };
              return a;
            }
          });
          simEntities.forEach((item) => {
            mergedMap.set(item.entity_id, item);
          });

          // Add GROUP data, updating if entity_id already exists
          groupsArray.forEach((item) => {
            if (mergedMap.has(item.entity_id)) {
              // Merge data if entity exists
              const existing = mergedMap.get(item.entity_id);
              mergedMap.set(item.entity_id, { ...existing, ...item });
            } else {
              mergedMap.set(item.entity_id, item);
            }
          });

          // Convert map back to an array
          const mergedArray = Array.from(mergedMap.values());
          this.report.entities = mergedArray;
        } else {
          return false;
        }
        this.report.is_all_units = false;
      }
      const result = this.checkValidation(this.report);
      if (result.success) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: result.field,
            text: result.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      } else {
        EventBus.$emit("add-update-report", this.report);

        this.isApplyDisabledFlag = true;
        store.commit("report/SET_ENABLE_GENERATE", true);

        if (window.innerWidth <= 667) {
          this.isCustomizerOpen = !this.isCustomizerOpen; //Mobile
        }
      }
    },
    isValid() {
      if (
        !this.isApplyDisabledFlag &&
        this.selectedUnselectedItems &&
        (this.selectedUnselectedItems.unit.selected.length ||
          this.selectedUnselectedItems.group.selected.length ||
          this.selectedUnselectedItems.isAllUnit)
      ) {
        //   store.commit("report/SET_ENABLE_GENERATE", false);
        return false;
      } else {
        //    store.commit("report/SET_ENABLE_GENERATE", true);
        return true;
      }
    },
    setFilter(filter) {
      this.filters = filter;
    },
    unitFun(data, isAlreadySave, flag) {
      if (isAlreadySave) {
        this.selectedUnselectedItems[isAlreadySave] = flag;
      }
      data.selected.forEach((element) => {
        if (element && element.source === "GROUP") {
          if (
            !this.selectedUnselectedItems.group.selected.includes(element.id)
          ) {
            this.selectedUnselectedItems.group.selected.push(element.id);
          }
        } else if (element && element.source === "UNIT") {
          if (
            !this.selectedUnselectedItems.unit.selected.includes(element.id)
          ) {
            if (!element.is_selected) {
              if (!isAlreadySave) {
                this.selectedUnselectedFinalItems.unit.selected.push(
                  element.id
                );
              }
            }
            this.selectedUnselectedItems.unit.selected.push(element.id);
          }
        }
      });
      if (this.$route.name !== "notification-create") {
        data.unSelected.forEach((element) => {
          if (element && element.source === "GROUP") {
            if (
              !this.selectedUnselectedItems.group.unselected.includes(
                element.id
              )
            ) {
              this.selectedUnselectedItems.group.unselected.push(element.id);
            }
            if (
              this.selectedUnselectedItems.group.selected.includes(element.id)
            ) {
              this.selectedUnselectedItems.group.selected =
                this.selectedUnselectedItems.group.selected.filter(
                  (e) => e.id === element.id
                );
            }
          } else if (element && element.source === "UNIT") {
            if (
              !this.selectedUnselectedItems.unit.unselected.includes(element.id)
            ) {
              this.selectedUnselectedItems.unit.unselected.push(element.id);
            }
            if (
              this.selectedUnselectedItems.unit.selected.includes(element.id)
            ) {
              this.selectedUnselectedItems.unit.selected =
                this.selectedUnselectedItems.unit.selected.filter(
                  (e) => e.id === element.id
                );
            }
          }
        });
      }

      this.notification.units = [
        {
          type: "GROUP",
          items: this.selectedUnselectedItems.group
        },
        {
          type: "UNIT",
          items: this.selectedUnselectedItems.unit
        }
      ];
      // }
      this.selectedUnitByUser = this.notification.units;
      const alreadySelected = this.selectedUnitsData.map((e) => e.id);
      data.selected.forEach((e) => {
        if (!alreadySelected.includes(e.id)) {
          e.entity_id = e.id;
          e.entity_type = e.source == "GROUP" ? 1 : 0;
          this.selectedUnitsData.push(e);
          const n = this.report.entities.some((item) => item.id === e.id);
          if (!this.report.entities.some((item) => item.id === e.id)) {
            e.entity_id = e.id;
            e.entity_type = e.source == "GROUP" ? 1 : 0;
            this.report.entities.push(e);
          }
        }
      });
      const removedEntityCheck = this.report.entities.map((e) => e.id);
      const removedIndexes = [];
      removedEntityCheck.forEach((e, index) => {
        const r = data.unSelected.some((item) => item.id === e);
        if (data.unSelected.some((item) => item.id === e)) {
          removedIndexes.push(index);
        }
      });
      let i = 0;
      if (removedIndexes.length) {
        removedIndexes.sort((a, b) => b - a);
        removedIndexes.forEach((index) => {
          const removingItem = this.report.entities[index];
          this.report.entities.splice(index, 1);
          const itemIndex = this.selectedUnitsData.findIndex(
            (item) => item.id === removingItem.id
          );
          if (itemIndex >= 0) {
            this.selectedUnitsData.splice(itemIndex, 1);
          }
        });
      }
    },

    async getAllReportCategories(value) {
      try {
        this.show = true;
        const me = this;
        const requestData = {};
        if (value) {
          requestData.filter = value;
        }
        let response = await new ReportService().getAllReportCategories(
          requestData
        );
        this.show = false;
        if (response && response.data) {
          const a = response.data.list.map((e) => {
            e.id = e.category;
            e.label = e.category;
            e.children = e.list.map((ee) => {
              ee.id = ee.report_type;
              ee.label = ee.title;
              return ee;
            });
            return e;
          });
          this.reportCategories = a || [];
          // console.log(a);
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    getUnitLookupCount(count) {
      this.totalLookUpCount = count;
    },

    isValid() {
      if (
        !this.isApplyDisabledFlag &&
        this.selectedUnselectedItems &&
        (this.selectedUnselectedItems.unit.selected.length ||
          this.selectedUnselectedItems.group.selected.length ||
          this.selectedUnselectedItems.isAllUnit) &&
        this.report.account_id &&
        this.report.report_type
      ) {
        return false;
      } else {
        return true;
      }
    },
    async getCreatedReportAPI() {
      try {
        this.show = true;
        const me = this;
        let response = await new ReportService().getCreatedReport({
          report_id: this.$route.params.id
        });
        this.show = false;
        if (response && response.data) {
          this.report = response.data;
          this.report.report_type = this.report.processor;
          if (
            response.data.status == "PROCESSING" ||
            response.data.status == "NOT_STARTED"
          ) {
            setTimeout(() => {
              store.commit("report/SET_ENABLE_GENERATE", true);
              this.isApplyDisabledFlag = true;
            }, 500);
          }
          this.report.account_id =
            response.data.account_id || localStorage.getItem("USER_ACCOUNT_ID");
          this.selectedAccount = this.report.account_id;
          await this.getReportTypeDetails();

          if (response.data.is_all_units) {
            this.isAllUnit = true;
            this.isAllSelectedUnit = true;
            this.report.is_all_units = true;
            this.report.entities = [];
          } else if (response.data.is_all_sims) {
            this.isAllSims = true;
            this.isSimValid = true;
            this.report.is_all_sims = true;
            this.report.entities = [];
          } else {
            this.isAllUnit = false;
            this.isAllSelectedUnit = false;
            const selectedSims = [];
            this.report.entities.forEach((element) => {
              if (element.source == "UNIT") {
                this.selectedUnselectedItems.unit.selected.push(element.id);
                this.selectedUnselectedFinalItems.unit.selected.push(
                  element.id
                );
              }
              if (element.source == "SIM") {
                selectedSims.push(element.id);
              } else {
                this.selectedUnselectedItems.group.selected.push(element.id);
                this.selectedUnselectedFinalItems.group.selected.push(
                  element.id
                );
              }
            });
            this.sims = selectedSims || [];
            this.selectedUnitsData = this.report.entities;
            this.totalLookUpCount = this.report.entities.length;

            // this.selectedUnselectedItems.unit.selected.forEach((element) => {
            //   this.report.entities.push({
            //     entity_type: 0,
            //     entity_id: element
            //   });
            // });
            // this.selectedUnselectedItems.group.selected.forEach((element) => {
            //   this.report.entities.push({
            //     entity_type: 1,
            //     entity_id: element
            //   });
            // });
          }

          this.$route.meta.breadcrumb = [
            ...constants.REPORTS_ROUTE_META_BREADCRUMB
          ];
          // EventBus.$emit("add-update-report", this.report);
          EventBus.$emit("report-socket", this.report);
          if (this.$route.name === "edit-report") {
            this.$route.meta.breadcrumb.push(
              ...[
                {
                  to: { name: "view-report", params: { id: response.data.id } },
                  text: response.data.report_name
                },
                {
                  text: "Breadcrumb_Edit",
                  active: true
                }
              ]
            );
          } else {
            this.$route.meta.breadcrumb.push({
              text: response.data.report_name
            });
          }
          this.isValid();
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    }
  },
  beforeDestroy() {
    EventBus.$off("check-is-have-unit-or-group", this.isApplyDisabled);
  }
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";
.unit-selection-responsive {
  height: auto !important;
}
.unit-filter-report {
  .unit-icon-modal {
    // filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.25)) !important;
    .unit-icon-card {
      box-shadow: none !important;
      padding: 8px 0px !important;
      height: auto !important;
      .card {
        box-shadow: none !important;
      }
      .adduser-btn-row {
        display: flex;
        align-items: center;
        justify-content: flex-start !important;
        .card-title {
          margin-bottom: 0px !important;
        }
      }

      .unit-selection-responsive.table-responsive {
        border-bottom: 1px solid #ebe9f1;
      }
    }
  }
  .unit-icon-modal {
    position: relative !important;
  }
  .customizer-section-report {
    .row {
      margin-right: 0rem;
      margin-left: 0rem;
    }
    &.unit-search-comp-ui {
      padding: 0 1rem;
      height: auto;
    }
    &.auto-search-padding-0 {
      padding: 0px;
    }
  }
  .unit-selection-responsive .table-responsive {
    max-height: calc(50vh - 210px);
    &.no-headers .table td {
      padding: 0.42rem 0rem;
    }
  }

  .b-avatar {
    width: 30px !important;
    height: 30px !important;
    .b-avatar-text {
      font-size: calc(14px) !important;
    }
  }
}
.customizer-section-report {
  padding: 0.5rem;
  // border-bottom: 1px solid #ebe9f1;
}

.ps-customizer-area {
  height: calc(100% - 110px);
}
.report-main-scroll {
  height: calc(100% - 160px);
  overflow: auto;
  overflow-x: hidden;
  padding: 0px 0px 0px 1px;
  .vue-treeselect {
    padding-left: 0.68rem;
    padding-right: 0.68rem;
  }
}

.report-generate {
  text-align: center;
  margin-top: 15px;
}
@media only screen and (max-width: 991px) {
  .report-main-scroll {
    height: calc(100% - 130px);
  }
}
</style>
<style lang="scss">
.report-main-scroll {
  .vue-treeselect__menu-container {
    width: calc(100% - 2rem) !important;
    margin-left: 1rem;
  }
}
.unit-filter-report {
  .adduser-btn-row {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    .card-title {
      margin-bottom: 0px !important;
    }
    .card-title {
      margin-bottom: 0px !important;
      margin-left: 5px !important;
    }
  }
}
.header-report-filter {
  padding-left: 0.6rem !important;
}
.dark-layout .form-control,
.reportrange-text {
  border: 1px solid #404656 !important ;
  background-color: transparent !important;
}
.mt-10-mb {
  margin-bottom: 0 !important;
}
.unit-icon-modal {
  width: 100%;
}
.dark-layout .unit-icon-modal {
  border: 1px solid #404656 !important ;
  background-color: transparent !important ;
  filter: none !important;
  border-radius: 0.357rem;
  margin-top: 10px;
  padding: 2px 0 !important;
}
.unit-selection-responsive {
  height: auto !important;
}
.sim-checkbox {
  margin-left: 4%;
  margin-bottom: 10px;
}
.sim-dropdown {
  margin-left: 4%;
  margin-right: 4%;
}
</style>
